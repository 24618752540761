import { useRecordContext } from "react-admin"

import { Rating} from 'react-simple-star-rating';




export const RatingField = (props: any) => {
    const {source} = props;
    const record = useRecordContext();
    
    const rating = record ? record.rating * 10 : 0;
    
    return (
    // @ts-ignore
        <Rating
        ratingValue={rating}
        size={20}
        stars={5}
        label
        transition
        fillColor='orange'
        emptyColor='gray'>
      {rating}
      </Rating>

    )
    
}

