import * as React from "react";
import "./App.css";

import { Admin, Resource } from "react-admin";
import { dataProvider } from "./api/dataProvider";
import authProvider from "./api/authProvider";

import Wines from "./wines";

const App = () => (
  <Admin
    // @ts-ignore
    dataProvider={dataProvider}
    authProvider={authProvider}
    requireAuth
  >
    <Resource name="wines" {...Wines} />
    <Resource name="countries" />
    <Resource name="grapes" />
    <Resource name="manufacturers" />
    <Resource name="foods" />
    <Resource name="comments" />
  </Admin>
);

export default App;
