import {
  Button,
  EditButton,
  ImageField,
  ReferenceField,
  Show,
  SimpleShowLayout,
  TextField,
  TopToolbar,
  useRecordContext,
  useCreate,
  ReOrderButtons,
  ArrayField,
  ChipField,
  Labeled,
  useGetList,
  Loading,
} from "react-admin";
import { Typography, Chip } from "@mui/material";
import { RatingField } from "../Rating";
import { get } from "lodash";

const WineShow = () => {
  const record = useRecordContext();

  return (
    <Show
      queryOptions={{
        meta: { expand: "category,manufacturer.country,combines_with" },
      }}
      title="Wine details"
      actions={<WineShowActions />}
      aside={<WineShowComments />}
    >
      <SimpleShowLayout>
        <TextField variant="h4" source="name" />
        <TextField source="year" />
        <ImageField
          source="image_url"
          label="Image"
          sx={{ minHeight: "20em" }}
        />
        <TextField source="description" />

        {/* <ReferenceField label="Manufacturer" source="manufacturer" reference="manufacturers">
    <TextField source="name"/>
    </ReferenceField>
     */}
        <TextField source="@expand.manufacturer.name" label="Manufacturer" />
        <TextField source="@expand.category.name" label="Category" />
        <TextField source="price" />
        <TextField
          source="@expand.manufacturer.@expand.country.name"
          label="Country"
        />

        {/* <Labeled label="Combines with">
        <>        
        {get(record, "@expand.combines_with").map((food: string) => {
          // @ts-ignore
          <Chip>{food.name}</Chip>
        })}
          
</>
                </Labeled>
<ChipField source="@expand.combines_with[0].name"/> */}

        <RatingField source="rating" />

        <TextField source="consumed_count" />
      </SimpleShowLayout>
    </Show>
  );
};

export default WineShow;

const WineShowActions = () => {
  const record = useRecordContext();

  const [create, { data, isLoading, error }] = useCreate("comments", {
    data: { wine: record ? record.id : "", comment: "" },
  });

  const onClick = () => {
    create("comments", { data: { wine: record.id, comment: "" } });
  };

  return (
    <TopToolbar>
      <EditButton />
      <Button label="Mark 1 consumed" onClick={onClick} />
    </TopToolbar>
  );
};

const WineShowComments = () => {
  const record = useRecordContext();

  console.log(record);

  const { data, total, isLoading, error } = useGetList("comments", {
    filter: { wine: record ? record.id : "undefined" },
    pagination: { page: 1, perPage: 10 },
    sort: { field: "created", order: "DESC" },
  });
  if (isLoading) {
    return <Loading />;
  }
  if (error) {
    return <p>ERROR</p>;
  }

  if (!data) {
    return <p>No comments</p>;
  }

  return (
    <>
      {data.map((comment: any) => (
        <div>
          <Typography variant="body2">
            {comment.created} - {comment.comment}
          </Typography>
        </div>
      ))}
    </>

    //
    //
  );
};
