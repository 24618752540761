import { AuthProvider, UserIdentity } from "react-admin";
import { config } from "../env";
import { client } from "./dataProvider";



interface LoginFields {
  username: string;
  password: string;
}

const authProvider: AuthProvider = {
  login: (login: LoginFields) => {
    return client.Users.authViaEmail(login.username, login.password).then(
        data => {
            localStorage.setItem("auth", data.token);
            localStorage.setItem("user", JSON.stringify(data.user));
            return Promise.resolve();
        }

    )
  },
  logout: () => {
    localStorage.removeItem("auth");
    localStorage.removeItem("user");
    return Promise.resolve();
  },
  checkAuth: () =>
    localStorage.getItem("auth") ? Promise.resolve() : Promise.reject(),
  // @ts-ignore
  checkError: (error) => Promise.resolve(),
  getPermissions: () => {
    const isAdmin = localStorage.getItem("is_admin");
    const permissions = {
      admin: isAdmin,
    };
    return Promise.resolve(permissions);
  },
  getIdentity: (): Promise<UserIdentity> => {
    const user = localStorage.getItem("userId");
    return Promise.resolve({
      id: user ? user : "",
      fullName: ""
    });
  },
  isAdmin: () => {
    const admin = localStorage.getItem("is_admin");
    return admin === "true";
  },
};

export default authProvider;
