import { useState } from "react";
import {
  Button,
  Create,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextField,
  TextInput,
  useDataProvider,
  useRedirect,
  useNotify,
} from "react-admin";

const WineCreate = () => {
  const dataProvider = useDataProvider();
  const redirect = useRedirect();
  const notify = useNotify();

  const [link, setLink] = useState("");

  const onSetLink = (e: any) => {
    setLink(e.target.value);
  };

  const onImport = () => {
    // @ts-ignore
    dataProvider.importLink(link).then((result) => {
      console.log(result);
      if (result.data) {
        notify("Imported wine")
        redirect(`/wines/${result.data.id}/show`)
      }
    });
  };

  return (
    <Create>
      <SimpleForm>
        <TextInput
          source="link"
          label="Import url"
          onChange={onSetLink}
          value={link}
        />
        <Button label="Import" onClick={onImport} />

        <TextInput source="name" />
        <TextInput source="year" />
        <ReferenceInput source="manufacturer" reference="manufacturers">
          <SelectInput required label="Manufacturer" optionText="name" />
        </ReferenceInput>
        <ReferenceInput source="grape" reference="grapes">
          <SelectInput required label="Grape" optionText="name" />
        </ReferenceInput>
        <ReferenceInput source="category" reference="category">
          <SelectInput required label="Category" optionText="name" />
        </ReferenceInput>
        <TextInput required source="rating" />
      </SimpleForm>
    </Create>
  );
};

export default WineCreate;
