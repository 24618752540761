import {
  CreateButton,
  Datagrid,
  ExportButton,
  FilterList,
  FilterListItem,
  FunctionField,
  List,
  ReferenceField,
  SortButton,
  TextField,
  TextInput,
  TopToolbar,
  useGetList,
  ImageField,
} from "react-admin";

import CategoryIcon from "@mui/icons-material/Category";
import { Box, Card, CardContent, useMediaQuery } from "@mui/material";
import { RatingField } from "../Rating";

const WineList = () => {
  // @ts-ignore
  const isDesktop = useMediaQuery(theme => theme.breakpoints.up('md'));
  
  return (
    <List
      aside={<FilterSidebar />}
      actions={<ListActions />}
      filters={WineListSearch}
    >
      <Datagrid rowClick={"show"}>
        <FunctionField
          label="Name"
          // @ts-ignore
          render={(record) => `${record.name})`}
        />
        <RatingField source="rating" />
        
        <TextField source="price"/>
      </Datagrid>
    </List>
  );
};

export default WineList;

const ListActions = () => (
  <TopToolbar>
    <SortButton
      fields={[
        "year",
        "name",
        "grape",
        "manufacturer",
        "category",
        "consumed_count",
        "rating",
        "price",
      ]}
    />
    <CreateButton />
    <ExportButton />
  </TopToolbar>
);

const GrapeFilter = () => {
  const { data, isLoading, error } = useGetList("grapes");

  return (
    <FilterList label="Grape" icon={<CategoryIcon />}>
      {data
        ? data.map((grape) => (
            <FilterListItem label={grape.name} value={{ grape: grape.id }} />
          ))
        : null}
    </FilterList>
  );
};

const CategoryFilter = () => {
  const { data, isLoading, error } = useGetList("category");

  return (
    <FilterList label="Category" icon={<CategoryIcon />}>
      {data
        ? data.map((category) => (
            <FilterListItem
              label={category.name}
              value={{ category: category.id }}
            />
          ))
        : null}
    </FilterList>
  );
};

const FoodFilter = () => {
  const { data, isLoading, error } = useGetList("foods");

  return (
    <FilterList label="Foods" icon={<CategoryIcon />}>
      {data
        ? data.map((food) => (
            <FilterListItem
              label={food.name}
              value={{ combines_with: food.id }}
            />
          ))
        : null}
    </FilterList>
  );
};

const CountryFilter = () => {
  const { data, isLoading, error } = useGetList("countries");

  return (
    <FilterList label="Countries" icon={<CategoryIcon />}>
      {data
        ? data.map((country) => (
            <FilterListItem
              label={country.name}
              value={{ country: country.id }}
            />
          ))
        : null}
    </FilterList>
  );
};

const CountFilter = () => {
  //const { data, isLoading, error } = useGetList("countries");

  return (
    <FilterList label="Status" icon={<CategoryIcon />}>
      <FilterListItem label={"New"} value={{ consumed_count: 1 }} />
    </FilterList>
  );
};

const FilterSidebar = () => (
  <Box
    sx={{
      display: {
        xs: "none",
        sm: "block",
      },
      order: -1, // display on the left rather than on the right of the list
      width: "15em",
      marginRight: "1em",
    }}
  >
    <Card>
      <CardContent>
        <CategoryFilter />
        <GrapeFilter />
        <CountFilter />
        {/* <FoodFilter /> */}
      </CardContent>
    </Card>
  </Box>
);

const WineListSearch = [<TextInput label="Search" source="search" alwaysOn />];
