

import PocketBase from 'pocketbase';
import { config } from '../env';

export const client = new PocketBase(config.url);



export const dataProvider = {
    getList: (resource: string, params: any) => {
        let queryParams = {};
        if (Object.keys(params.filter).length > 0) {
            let filter = "(";
            for (const key in params.filter) {
                if (filter !== "(") {
                    filter += " && "

                }

                let val = params.filter[key];
                if (typeof val === 'string') {
                    val = `'${val}'`
                }
                if (key === "search") {
                    filter += `name~'${params.filter[key]}'`
                } else if (key === "consumed_count") {
                    filter += `${key}<${params.filter[key]}`;
                } else {
                    filter += `${key}=${val}`;
                }
            }
            filter += ")"
            queryParams = { ...queryParams, filter: filter };
        }

        if (params.sort) {
            const order = params.sort.order === "DESC" ? "-" : "+";
            const sort = `${order}${params.sort.field}`;
            queryParams = { ...queryParams, sort };
        }

        return client.Records.getList(resource, params.pagination.page, params.pagination.perPage, queryParams).then(data => {
            // @ts-ignore
            return { data: data.items, total: parseInt(data.totalItems) }
        })
    },
    getOne: (resource: string, params: any) => {
        const opts = params.meta.expand ? { expand: params.meta.expand } : {};
        return client.Records.getOne(resource, params.id, opts).then(data => {
            return { data: { ...data, image_url: client.Records.getFileUrl(data, data.image) } };
        }).then(data => {
            return data;
        })
    },
    getMany: (resource: string, params: any) => Promise,
    getManyReference: (resource: string, params: any) => Promise,
    create: (resource: string, params: any) => {
        return client.Records.create(resource, params.data).then(data => ({ data: data }))
    },
    update: (resource: string, params: any) => Promise,
    updateMany: (resource: string, params: any) => Promise,
    delete: (resource: string, params: any) => Promise,
    deleteMany: (resource: string, params: any) => Promise,

    getWineImage: (resource: string, params: any) => {

    },

    importLink: (link: string) => {
        return client.send("api/import/alko", { method: 'POST', body: { link: link } }).then(data => { return { data: data } })
    }

}
