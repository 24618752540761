import WineCreate from "./Create";
import WineList from "./List";
import WineShow from "./Show";


export default {
    list: WineList,
    show: WineShow,
    create: WineCreate,
}
